#change-password-page {
    background: white;
    width: 100%;
    height: 100vh;
    padding: 5vw;
    display: flex;
    flex-direction: column;
    align-items: center;
    color: #404040
}

.change-password-page-container {
    width: 40%;
    max-width: 350px;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.change-password-user-name{
    width: 100%;
    text-align: left;
}

.change-password-user-email {
    width: 100%;
    margin-bottom: 3vh;
    text-align: left;
    p {
        display: inline-block;
        font-size: 13px;
        border: 1px solid #000000;
        border-radius: 50px;
        padding: 2px 15px;
    }
}


.change-password-header{
    width: 100%;
    margin: 10px 0px;
    text-align: left;
    font-size: 13px;
}

#change-password-form {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;


    input {
        width: 100%;
        border-radius: 10px;
        padding: 15px 10px;
        margin: 10px 0px;
        border: 1px solid #c8c8c8;
    }

    button {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        width: 100%;
        margin: 10px 0px;
        padding: 10px;
        background: #3e4684;
        color: hsl(0 0 100);
        border: none;
        border-radius: 30px;
    }

    .change-password-forgot-password-button {
        background: none;
        border: none;
        padding: 0px;
        text-decoration: none;
        font-size: 15px;
        color: hsl(0, 0%, 43%);
        font-family: 'Roboto', sans-serif;
    }

    button:hover {
        background-color: #1a295e;
    }
}


@media (max-width: 600px),
(max-height: 600px) {

    #change-password-page {
        height: auto;
    }

    .change-password-page-container {
        width: 100%;
    }

}