.verify-email-page {
  position: fixed;
  top: 0;
  left: 0;
  background: white;
  width: 100%;
  height: 100vh;
  padding: 5vw;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  z-index: 1;
}

.verify-email-page-container {
  height: 100%;
  width: 40%;
  max-width: 350px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.verify-email-form {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  input {
    width: 100%;
    border-radius: 10px;
    padding: 15px 10px;
    margin: 10px 0px;
    border: 1px solid #c8c8c8;
  }

  button {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    margin: 10px 0px;
    padding: 10px;
    background: #3e4684;
    color: hsl(0 0 100);
    border: none;
    border-radius: 30px;
  }
}

.verify-email-header-container {
  width: 100%;
  margin: 5vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.verify-email-header-image-container {
  display: grid;
  justify-content: center;
  align-items: center;
}

.verify-email-image {
  height: 80px;
  display: grid;
  justify-content: center;
  align-items: center;
}

.verify-email-email-sent-to-div{
  width: 100%;
  color: #5F5F5F;
  font-size: 15px;
  text-align: center;
}
.verify-email-sent-email-div{
  color: black;
  font-size: 17px;
  background: rgb(241, 241, 241);
  border-radius: 5px;
  padding: 1px 5px;
}
.verify-email-suggestion-div{
  width: 100%;
  text-align: center;
  color: #5F5F5F;
  font-size: 13px;
}

.verify-email-back-button {
  margin-bottom: 5px;
  margin-top: auto;
  background: none;
  border: none;
  color: hsl(0, 0%, 42%);
}

@media (max-width: 600px), (max-height: 600px) {
  .verify-email-page {
    height: calc(100vh - 30px);
    width: 100%;
  }

  .verify-email-page-container {
    width: 100%;
  }
  .verify-email-email-sent-to-div{
    font-size: 12px;
  }
  .verify-email-sent-email-div{
    font-size: 13px;
  }
  .verify-email-suggestion-div{
    font-size: 10px;
  }
}
