/* Background blur effect */
#delete-account-blur-background {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background: rgba(0, 0, 0, 0.4);
    backdrop-filter: blur(5px);
    pointer-events: none;
    z-index: 1;
}

#delete-account-overlay {
    position: fixed;
    top: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0);
    z-index: 2;
}

#delete-account-box {
    position: fixed;
    width: 50%;
    padding: 50px;
    background-color: white;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border-radius: 10px;
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.2);
    z-index: 3;
}

#delete-account-box-message {
    margin-bottom: 50px;
    text-align: center;
    font-weight: bold;
    font-size: 16px;
    color: #333;
}

#delete-account-box-password-input-div {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    input {
        width: 100%;
        padding: 5px;
        box-shadow: 0px 0px 5px rgb(211, 211, 211);
        border-radius: 4px;
        border: none;
        box-sizing: border-box;
        color: 030a15;
        margin: 10px;
    }
}

#delete-account-box-button-div {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin: 10px;
    color: #585858;

    button {
        margin: 10px;
        background: none;
        border: 1px solid #a9a9a9;
        color: #0e1634;
        border-radius: 5px;
        padding: 5px 20px;
    }

    button:hover {
        background-color: #0e1634;
        color: white;
    }
}


@media (max-width: 600px),
(max-height: 600px) {

    #delete-account-box {
        width: 90%;
    }

    #delete-account-close-button-div {
        margin: 0px 10px;

        button {
            padding: 4px 15px;
        }
    }

}