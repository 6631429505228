/* Full-page blink effect that covers the entire screen */
.screen-blink {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: transparent; /* Start transparent */
    transition: background-color 1s ease;
    z-index: 9999;
    display: flex;
    justify-content: center;
    align-items: center;
    pointer-events: none; /* Allow interactions with the content underneath */
    visibility: hidden; /* Start hidden */
    opacity: 0;
}

/* When the blink effect is active */
.screen-blink-active {
    background-color: transparent; /* Blink color */
    visibility: visible; /* Make it visible */
    opacity: 1;
}

/* Simple message text at the bottom */
.blink-message {
    position: absolute;
    bottom: 20px;
    left: 50%;
    transform: translateX(-50%);
    font-size: 16px;
    color: #000;
    opacity: 0;
    transition: opacity 0.5s ease;
}

.blink-message-visible {
    opacity: 1;
}
