/* Background blur effect */
.popup-blur-background {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.7);
  z-index: 130;
  pointer-events: none;
  opacity: 0;
  transition: opacity 0.5s ease;
}

.popup-blur-background.show {
  opacity: 1;
}

.popup-overlay {
  position: fixed;
  top: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  z-index: 130;
  opacity: 0;
  transition: opacity 0.5s ease;
}

.popup-overlay.show {
  opacity: 1;
}

/* Popup box styling */
.popup-box {
  width: 100%;
  max-width: 400px;
  padding: 20px;
  background: #fff;
  border-radius: 20px;
  text-align: center;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.2);
  z-index: 130;
  transition: transform 0.5s ease, opacity 0.5s ease;
  transform: translateY(-50px);
  opacity: 0;
}

.popup-box.show {
  transform: translateY(0);
  opacity: 1;
}

.popup-message {
  font-family: Arial, sans-serif;
  font-size: 15px;
  color: #5f5f5f;
  margin-bottom: 20px;
}

.popup-close-button-div {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  button {
    background: none;
    border: 1px solid #0e1634;
    color: #0e1634;
    border-radius: 5px;
    padding: 5px 30px;
    cursor: pointer;
    transition: all 0.3s ease-out;
  }

  button:hover {
    background-color: #0e1634;
    color: white;
  }
}

@media (max-width: 700px), (max-height: 700px) {
  .popup-box {
    width: 80%;
  }
  .popup-message {
    font-size: calc(0.4em + 2vw);
  }
}
