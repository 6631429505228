/* Modal overlay and content styling */
.product-details-page {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #000000b3;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    z-index: 125;
    color: rgb(190, 190, 190);
}

.product-details-content {
    background-color: #030a15;
    padding: 20px;
    border-radius: 10px;
    width: 90%;
    /* Changed to percentage for better responsiveness */
    max-width: 800px;
    max-height: 80vh;
    overflow-y: auto;
    z-index: 125;
    position: relative;
}

.product-details-header {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 20px;

    h2 {
        text-align: center;
        width: 100%;
    }
}

.product-details-close-button {
    color: white;
    background: none;
    border: none;
    font-size: 30px;
    cursor: pointer;
}

.product-details-body {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 10px 0;
}

.product-detail-loading-spinner {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 40vh;
}

#product-details-table-div {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.product-details-table {
    width: 100%;
    border-collapse: collapse;
    margin-bottom: 20px;

    td {
        padding: 10px;
        vertical-align: middle;
    }

    td:first-child {
        font-weight: bold;
        width: 50%;
        text-align: right;
    }

    td input {
        background: #030a15;
        width: 100%;
        padding: 5px;
        border: 1px solid #ffffff;
        border-radius: 4px;
        box-sizing: border-box;
        color: white;
    }
}

.product-details-table-date-div {
    display: flex;
    flex-direction: row;
    align-items: baseline;
    color: rgb(150, 150, 150);
    font-size: 15px;
}

.product-details-table-time-div {
    margin-left: 5px;
    font-size: 10px;
}

.product-details-edit-save-button-div {
    margin: 20px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;

    button {
        background: none;
        border: 1px solid white;
        color: white;
        border-radius: 5px;
        padding: 5px;
        margin: 5px;
    }

    button:hover {
        background-color: #0e1634;
    }
}

.product-details-price-chart-container{
    height: 100%;
    margin: 20px;
}

.product-details-remove-button {
    width: 100%;
    max-width: 400px;
    background: white;
    border: none;
    color: #030a15;
    padding: 10px 0px;
    border-radius: 20px;
    margin: 5px 0px;
}

/* Add a little extra responsiveness */
@media only screen and (max-width: 768px) {
    .product-details-content {
        width: 95%;
        padding: 10px;
    }

    .product-details-table td:first-child {
        width: 40%;
    }

    .product-details-table-date-div {
        font-size: calc(0.5em + 1vw);
    }

    .product-details-table-time-div {
        font-size: calc(0.4em + 2vw);
    }

    .product-details-remove-button {
        padding: 5px 0px;
    }
}