#product-list-page {
    background: white;
    width: 100%;
    min-width: 200px;
    min-height: 90vh;
}

#product-list-page-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 20px;
}

#product-list-product-number{
    width: 100%;
    font-size: 15px; 
    color: #5F5F5F;
    background-color: white;
    padding: 10px 0px;
    font-weight: 700;
}

#product-list-header-div {
    color: #a9a8a8;
    margin: 3vh;
    text-align: center;
}

#product-list-data-container {
    background: hsl(218deg 50% 91%);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.product-list-table {
    border-collapse: collapse;
    width: 80vw;
    max-width: 1000px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}

.product-list-table.loading {
    border-collapse: collapse;
    width: 90vw;
    max-width: 1000px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    min-height: 60vh;
}

.product-list-table th {
    padding: 12px 15px;
    border: 1px solid #ddd;
    text-align: center;
    color: white;
    background-color: rgb(40 65 91);
    font-weight: bold;
}

.product-list-table {
    
    .product-list-image-column {
        padding: 2px 5px;
        img {
            height: 40px;
            width: 40px;
            padding: 5px;
        }
        img:hover {
            cursor: pointer;
            box-shadow: 0px 0px 2px #5F5F5F;
            border-radius: 50%;
        }
    }
    td {
        color: rgb(66, 66, 66);
        padding: 12px 15px;
        border: 1px solid #ddd;
        text-align: center;
    }
}

.product-list-details-button {
    color: white;
    background-color: rgb(51, 80, 111);
    padding: 5px 15px;
    border-radius: 4px;
    border: none;
    cursor: pointer;
}

.product-list-details-button:hover {
    background-color: rgb(8, 22, 37);
}

@media only screen and (max-width: 768px) {

    #product-list-page {
        padding: 20px 10px;
    }

    #product-list-page-container {
        padding: 0px;
    }

    #product-list-product-number{
        font-size: 10px; 
        padding: 5px 0px;
    }

    #product-list-header-div {
        color: #a9a8a8;
        margin: 1vh;
        text-align: center;
    }

    .product-list-spinner-container{
        width: 80vw;
        height: 60vh;
    }

    .product-list-table {
        font-size: calc(0.5em + 1.5vw);
        width: 90vw;
        max-width: 100%;
    }

    .product-list-table th,
    .product-list-table tr {
        padding: 10px;
    }

    .product-list-table td {
        padding: 10px;
    }

    .product-list-details-button {
        padding: 5px 10px;
    }
    .product-list-details-button:hover {
        box-shadow: none;
    }

}
    