.carousel-container {
    position: relative;
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    overflow: hidden;
}

.carousel-slide {
    position: absolute;
    left: 0;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    transition: all 0.5s ease-in-out;

    p {
        font-size: 12px;
        text-align: center;
        margin: 5px 0px;
        font-weight: bold;
        color: #030a15;
    }
}

.carousel-slide-platform-buttons {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.trynow-carousel-trynow-button {
    background: #030a15;
    padding: 5px 15px;
    border: none;
    border-radius: 30px;
    color: white;
    font-size: 13px;
    font-weight: bold;

    img {
        height: 18px;
        margin-left: 3px;
    }

}

.hidden-slide-left {
    transform: translateX(-100%);
    opacity: 0;
}

.active-slide-left {
    transform: translateX(0);
    opacity: 1;
}

.hidden-slide-right {
    transform: translateX(100%);
    opacity: 0;
}

.active-slide-right {
    transform: translateX(0);
    opacity: 1;
}

.platform-selection {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    padding: 10px 0px;
    border-radius: 20px;
}

.platform-icon {
    cursor: pointer;
    text-align: center;
    margin: 0px 10px;
    box-shadow: 0px 0px 4px #030a15;
    border-radius: 50px;
    padding: 7px;

    img {
        width: 26px;
        height: 25px;
    }
}

.carousel-slide-auth-buttons {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}

.auth-button {
    background: #030a15;
    border: none;
    color: white;
    border: none;
    padding: 7px 15px;
    margin: 0 7px;
    font-size: 12px;
    border-radius: 20px;
    cursor: pointer;
    transition: background-color 0.3s ease;
}