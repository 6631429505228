.register-page {
    background: white;
    width: 100%;
    height: 100vh;
    padding: 30px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.register-page-container {
    height: 100%;
    width: 60%;
    max-width: 600px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.register-header-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: #5F5F5F;
    margin: 5vh;
}

.register-form {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    input {
        width: 100%;
        border-radius: 10px;
        padding: 15px 10px;
        margin: 10px;
        border: 1px solid #c8c8c8;
    }

    button {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        width: 100%;
        max-width: 300px;
        margin: 10px 0px;
        padding: 10px;
        background: #3e4684;
        color: hsl(0 0 100);
        border: none;
        border-radius: 30px;
    }

    .register-username-availability {
        width: 100%;
        text-align: center;
        font-size: 10px;
    }
    .password-match-status{
        font-size: 10px;
        color: rgb(241, 115, 115);
        text-align: left;
    }

    .password-validation-status{
        width: 100%;
        padding: 0px ;
        font-size: 10px;
    }
}

.register-input-grid {
    width: 100%;
    display: flex;
    flex-direction: row;
}

.register-login-button {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    a {
        color: #1e3a8a;
        text-decoration: none;
        font-family: 'Roboto', sans-serif;
        font-weight: bold;
    }
}

.register-home-button {
    margin-bottom: 5px;
    margin-top: auto;
    background: none;
    border: none;
    color: hsl(0, 0%, 42%);
}

@media (max-width: 600px),
(max-height: 600px) {

    .register-page {
        height: calc(100vh - 20px);
    }

    .register-page-container {
        width: 100%;
    }

    .register-header-container {
        margin: 20px;
    }

    .register-input-grid {
        flex-direction: column;
    }

    .register-form{
        input {
            margin: 7px 0px;
        }
        button {
            width: 100%;
        }
        .register-username-availability {
            font-size: calc(0.2em + 2vw);
            text-align: left;
        }
        .password-validation-status{
            font-size: 10px;
        }
    }

    
}