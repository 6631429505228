.navbar-main-div {
    background: white;
    width: 100%;
    height: 10vh;
    min-height: 50px;
    display: flex;
    flex-direction: row;
    justify-content: left;
    align-items: center;
    background-color: none;
    position: sticky;
    top: 0%;
    z-index: 120;
}

.navbar-logo-div {
    display: grid;
    justify-content: center;
    align-items: center;

    img {
        margin: 0px 40px;
        width: 150px;
    }
}

.navbar-links-main-div {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: left;
    align-items: center;
}

.navbar-links-div {
    margin: 0px 15px;

    a {
        text-decoration: none;
        color: #5f5f5f;
    }
}

.navbar-logout-div {
    margin-left: auto;
    margin-right: 50px;
    padding: 5px;

    button {
        background: none;
        border: none;
        color: #5f5f5f;
    }

    button:hover {
        color: black;
        background: none;
    }
}

.navbar-menu-icon-div {
    display: none;
    flex-direction: column;
    margin-right: 20px;
    margin-left: auto;

    img {
        height: 30px;
        width: 30px;
        cursor: pointer;
    }
}

@media only screen and (max-width: 700px) {
    .navbar-logo-div img {
        margin: 0px 10px;
    }

    .navbar-links-div {
        margin: 0px 10px;
    }

    .navbar-links-main-div {
        display: none;
    }

    .navbar-menu-icon-div {
        display: flex;
    }
}
