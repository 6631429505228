#profile-page {
  padding: 15px;
  display: grid;
  justify-content: center;
  gap: 20px;
  grid-template-columns: 1fr; /* 1 column layout */
  background: white;
}

.profile-subheading {
  width: 100%;
  font-size: 14px;
  color: #5F5F5F;
  text-align: center;
  text-align: left;
}

.profile-info-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding: 10px;
  border-radius: 10px;
  border: 1px solid #ababab;

  hr {
    color: #ababab;
    margin: 0px 10px;
    width: 100%;
  }
}

.profile-info-button {
  padding: 20px 5px;
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  background: none;
  border: none;
}

.profile-info-button:hover {
  background-color: rgb(220, 220, 220);
}

.profile-info {
  width: 90%;
  text-align: left;
  color: #5F5F5F;
  font-size: 15px;
  font-weight: 600;

  .profile-info-label {
    width: 100%;
    text-align: left;
    color: #5F5F5F;
    font-size: 10px;
    font-weight: 700;
  }

  .profile-info-value {
    text-align: left;
    color: #5F5F5F;
    font-size: 15px;
    font-weight: 400;
  }
}

.profile-info-change-icon {
  height: 100%;
  width: 10%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  img {
    height: 100%;
    width: 100%;
    max-width: 20px;
    max-height: 20px;
  }
}

.profile-switch-setting-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  padding: 10px;
  border-radius: 10px;
  border: 1px solid #ababab;

  hr {
    color: #ababab;
    margin: 0px 10px;
    width: 100%;
  }
}

.profile-switch-setting-div{
  padding: 15px 5px;
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  background: none;
  border: none;
  z-index: 1;

  .profile-switch-setting-label {
    width: 80%;
    text-align: left;
    color: #5F5F5F;
    font-size: 15px;
    font-weight: 600;
  }

  .profile-switch-setting-button{
    width: 20%;
  }
}

.profile-account-privacy-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  padding: 10px;
  border-radius: 10px;
  border: 1px solid #ababab;

  hr {
    color: #ababab;
    margin: 0px 10px;
    width: 100%;
  }
}

/* For larger screens (PC layout) */
@media (min-width: 768px) {
  #profile-page {
    grid-template-columns: 50% 50%; /* 2-column layout */
    grid-template-rows: auto 50%; /* 2 rows */
    height: 100%; /* Full height for the container */
    gap: 10px;
  }

  .profile-info-container {
    grid-column: 1 / 2; /* Item 1 spans the first column */
    grid-row: 1 / 3; /* Item 1 spans both rows */
  }

  .profile-switch-setting-container {
    grid-column: 2 / 3; /* Item 2 in the second column */
    grid-row: 1 / 2; /* Item 2 in the first row */
  }

  .profile-account-privacy-container {
    grid-column: 2 / 3; /* Item 3 in the second column */
    grid-row: 2 / 3; /* Item 3 in the second row */
  }
}