.signin-page {
    background: white;
    width: 100%;
    height: 100vh;
    padding: 5vw;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.sigin-page-container {
    height: 100%;
    width: 40%;
    max-width: 350px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.signin-logo {
    height: 150px;
    margin: 3vh 0vh;
}

.signin-form {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    input {
        width: 100%;
        border-radius: 10px;
        padding: 15px 10px;
        margin: 10px 0px;
        border: 1px solid #c8c8c8;
    }

    button {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        width: 100%;
        margin: 10px 0px;
        padding: 10px;
        background: #3e4684;
        color: hsl(0 0 100);
        border: none;
        border-radius: 30px;
    }

    .sigin-forgot-password-button {
        background: none;
        border: none;
        padding: 0px;
        text-decoration: none;
        font-size: 15px;
        color: hsl(0, 0%, 43%);
        font-family: 'Roboto', sans-serif;
    }
}

.signin-register-button {
    width: 100%;
    margin-bottom: 10px;
    margin-top: auto;
    padding: 10px;
    background: hsl(0, 0%, 100%);
    color: hsl(233deg 36% 38%);
    border: 1px solid hsl(233deg 36% 38%);
    border-radius: 30px;
}

.signin-home-button {
    background: none;
    border: none;
    color: hsl(0, 0%, 42%);
}


@media (max-width: 600px),
(max-height: 600px) {

    .signin-page {
        height: calc(100vh - 50px);
    }

    .sigin-page-container {
        width: 100%;
    }

}