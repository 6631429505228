  .price-chart-main-div {
    color: #ffffff;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 5vh;
  }

  .price-chart-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 400px;
    width: 600px;
  }
  .price-chart-min-max-price-div {
    width: 100%;
    font-size: 12px;
    color: #333;
    padding: 10px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    transition: background-color 0.3s ease;
}

.price-chart-min-max-price-div:hover {
    background-color: #e3f2fd;
}

.price-info-label {
    font-weight: bold;
    color: #1976d2;
}

.price-info-value {
    color: #4caf50;
}

.date-time-info {
    font-size: 10px;
    color: #757575;
}


  .price-chart-time-range-buttons {
    button {
      text-decoration: none;
      background: none;
      color: white;
      border: 1px solid white;
      border-radius: 50%;
      padding: 5px 6px;
      margin: 5px;
    }
    button:hover {
      box-shadow: 0px 0px 4px white;
    }
  }

  .price-chart-loading-spinner-div{
    min-height: 15vh;
  }

  @media (max-width: 600px),
  (max-height: 600px) {

    .price-chart-main-div {
      padding: 20px 0px;
    }

    .price-chart-container {
      height: auto;
      width: auto;
    }

  }