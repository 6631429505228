.sidebar {
    transition: transform 0.3s ease-in-out;
    transform: translateX(100%);
    width: 200px;
    height: 100%;
    position: fixed;
    top: 0;
    right: 0;
    overflow: hidden;
    background-color: white;
    z-index: 160;
}

.sidebar.open {
    display: flex;
    flex-direction: column;
    transform: translateX(0);
    padding: 10px;
    z-index: 160;
}

.sidebar-backdrop {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background: rgba(0, 0, 0, 0.5);
    z-index: 150;
    opacity: 1;
    transition: opacity 0.3s ease;
}

.sidebar.open~.sidebar-backdrop {
    pointer-events: auto;
    /* Activate backdrop only when the sidebar is open */
    opacity: 1;
}

.sidebar-close-sidebar-button-div {
    width: 100%;
    display: flex;
    align-items: center;

    img {
        width: 20px;
    }
}

.sidebar-user-info-div {
    display: flex;
    flex-direction: column;
    justify-content: start;
    align-items: flex-end;
    margin: 30px 0px;
    padding: 5px 0px;
    border-bottom: 1px solid rgb(203, 203, 203);
}

.sidebar-user-info-name {
    color: #3e3e3e;
    font-weight: 700;
    font-size: large;
    overflow-wrap: anywhere;
}

.sidebar-user-info-email {
    font-weight: 500;
    color: #7e7e7e;
    font-size: 10px;
    overflow-wrap: anywhere;
}

.sidebar-links-container {
    display: flex;
    flex-direction: column;
    justify-content: start;
    align-items: center;
    width: 100%;
    height: 100%;

    button:hover {
        background: rgb(229, 229, 229);
    }
}

.sidebar-links-div {
    display: flex;
    flex-direction: row;
    justify-content: end;
    align-items: center;
    width: 100%;
    padding: 5px;
    border: none;
    background: none;
}

.sidebar-links-div div {
    height: auto;
    text-decoration: none;
    color: #5F5F5F;
    font-weight: 500;
    font-size: 17px;
    border: none;
    background: none;
}

.sidebar-links-div img {
    width: 20px;
    margin: 10px;
}

#profile-icon {
    width: 30px;
    margin: 5px;
}

.sidebar-logout-button-div {
    display: flex;
    flex-direction: row;
    justify-content: end;
    align-items: center;
    width: 100%;
    border-radius: 5px;
    padding: 5px;
    margin-bottom: 10px;
    margin-top: auto;
    border: none;
    background: none;
}

.sidebar-logout-button-div div {
    height: auto;
    text-decoration: none;
    color: #5F5F5F;
    font-weight: 500;
    border: none;
    background: none;
}

.sidebar-logout-button-div img {
    width: 20px;
    margin: 10px;
}
