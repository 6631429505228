.text-animation-container {
    font-family: 'Tahoma', sans-serif;
    font-size: 20px;
    font-weight: 500;
    position: relative;
    display: inline-block;
    color: #5f8cb9;
  }
  
  .animated-text {
    display: inline-block;
    position: relative;
  }
  
  .cursor {
    display: inline-block;
    background-color: #5f8cb9;
    width: 2px;
    height: 1.5rem;
    margin-left: 2px;
    animation: blink 1s infinite;
  }
  
  @keyframes blink {
    0%, 100% {
      opacity: 1;
    }
    50% {
      opacity: 0;
    }
  }
  