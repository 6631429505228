.change-email-page {
    background: white;
    width: 100%;
    height: 100vh;
    padding: 5vw;
    display: flex;
    flex-direction: column;
    align-items: center;
    color: #404040
}

.change-email-page-container {
    width: 40%;
    max-width: 350px;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.change-email-header {
    width: 100%;
    margin-bottom: 5vh;
    h2 {
        width: 100%;
        margin: 10px 0px;
        text-align: left;
        font-size: 13px;
    }
}

.change-email-form {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;


    input {
        width: 100%;
        border-radius: 10px;
        padding: 15px 10px;
        margin: 10px 0px;
        border: 1px solid #c8c8c8;
    }

    button {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        width: 100%;
        margin: 10px 0px;
        padding: 10px;
        background: #3e4684;
        color: hsl(0 0 100);
        border: none;
        border-radius: 30px;
    }
}

.change-email-email-sent-to-div {
    width: 100%;
    color: #5F5F5F;
    font-size: 15px;
    text-align: left;
}

.change-email-sent-email-div {
    width: 100%;
    color: #5F5F5F;
    text-align: left;
    font-size: 17px;
    border-radius: 5px;
    padding: 1px 5px;
}

.change-email-suggestion-div {
    margin-bottom: 10vh;
    margin-top: auto;
    color: #5F5F5F;
    text-align: center;
    font-size: 13px;
}

@media (max-width: 600px),
(max-height: 600px) {

    #change-email-page {
        height: auto;
    }

    .change-email-page-container {
        width: 100%;
    }

    .change-email-email-sent-to-div {
        font-size: 12px;
    }

    .change-email-sent-email-div {
        font-size: 13px;
    }
}