
body {
  background: white;
}

.container {
  width: 100%;
  max-width: 500px;
}

/* Timeline Style */
.timeline {
  position: relative;
  padding-left: 40px;
  margin: 0 0 0 30px;
  color: rgb(44, 44, 44);
}

.timeline:before {
  content: '';
  position: absolute;
  left: 0;
  top: 5px;
  width: 5px;
  height: 100%;
  background: white; /* $timeline-body-bg */
  border: 1px solid rgb(153, 153, 153);
  border-radius: 10px;
}

.timeline-container {
  position: relative;
  margin-bottom: 10px;
}

.timeline-icon {
  position: absolute;
  left: -63px;
  top: 5px;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  text-align: center;
  font-size: 2rem;
}

.timeline-icon i {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

.timeline-icon img {
  width: 60%;
  height: 60%;
}

.timeline-body {
  background: #b9d0e7; /* $timeline-body-bg */
  padding: 5px 2px 1px 15px;
  box-shadow: 1px 3px 9px rgba(0, 0, 0, 0.1); /* $timeline-body-shadow */
  position: relative;
  border-radius: 20px;
  p {
    font-weight: 300;
    line-height: 1.5;
    font-size: 12px;
  }
}

.timeline-body:before {
  content: '';
  background: inherit;
  width: 20px;
  height: 20px;
  display: block;
  position: absolute;
  left: -9px;
  top: 20px;
  transform: rotate(45deg);
  border-radius: 0 0 0 2px;
}



.timeline-title .badge {
  background: #4f537b; /* $secondary */
  padding: 5px 10px;
  border-radius: 3px;
  font-size: 12px;
  font-weight: bold;
}

.timeline-container .badge,
.timeline-container .timeline-icon{
  background: #81b1e1 !important;
}

.timeline-container .badge
{
  border-radius: 50px;
}


@media only screen and (max-width: 768px) {
    .timeline-body {
        p {
          font-weight: 300;
          line-height: 1.5;
          font-size: 10px;
        }
      }
}