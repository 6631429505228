@import url("https://fonts.googleapis.com/css?family=DM+Sans:400,500,700&display=swap");

input[type=radio] {
    display: none;
}

.card {
    position: absolute;
    width: 50px;
    height: 50px;
    left: 0;
    right: 0;
    margin: auto;
    transition: transform 0.8s ease;
    cursor: pointer;
    --bs-card-border-width: none;
}

.paltform-carousel-container {
    width: 100%;
    height: 100%;
    transform-style: preserve-3d;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
}

.cards {
    position: relative;
    height: 50px;
    width: 100%;
    max-width: 500px;
    margin-bottom: 20px;

    img {
        width: 50px;
        border-radius: 10px;
        object-fit: cover;
        /* box-shadow: 0px 0px 5px 0px rgba(81, 81, 81, 0.47); */
    }
}



#amazon:checked~.cards #song-3,
#flipkart:checked~.cards #song-1,
#myntra:checked~.cards #song-2 {
    transform: translatex(-150%) scale(.8);
    opacity: .4;
    z-index: 0;
}

#amazon:checked~.cards #song-2,
#flipkart:checked~.cards #song-3,
#myntra:checked~.cards #song-1 {
    transform: translatex(150%) scale(.8);
    opacity: .4;
    z-index: 0;
}

#amazon:checked~.cards #song-1,
#flipkart:checked~.cards #song-2,
#myntra:checked~.cards #song-3 {
    transform: translatex(0) scale(1);
    opacity: 1;
    z-index: 1;
}

.player {
    background-color: #fff;
    min-width: 320px;
    padding: 15px 10px;
    border-radius: 40px;

    .upper-part {
        width: 100;
        position: relative;
        display: flex;
        align-items: center;
        margin-bottom: 20px;
        padding-left: 20px;
        height: 40px;
        overflow: hidden;

        .play-icon {
            img {
                height: 25px;
                object-fit: cover;
            }
        }

        .info-area {
            width: calc(100% - 100px);
            position: absolute;
            top: 0;
            left: 70px;
            transition: transform .4s ease-in;
        }
    }



    .song-info {
        width: calc(100% - 32px);
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
    }

    .song-info .title {
        color: #403d40;
        font-size: 14px;
        line-height: 24px;
    }


    .sub-line {
        display: flex;
        justify-content: space-between;
        width: 100%;
    }

    .subtitle {
        font-size: 12px;
        line-height: 16px;
        color: #c6c5c6;
    }

    .time {
        font-size: 16px;
        color: #a5a5a5;
        font-weight: 500;
        margin-left: auto;
    }

    /* .progress-bar {
        height: 3px;
        width: 100%;
        background-color: #e9efff;
        border-radius: 2px;
        overflow: hidden;
    } */

    /* .progress {
        display: block;
        position: relative;
        width: 60%;
        height: 100%;
        background-color: #2992dc;
        border-radius: 6px;
    } */
}


#flipkart:checked~.player #test {
    transform: translateY(0);
}

#flipkart:checked~.player #test {
    transform: translateY(-40px);
}

#myntra:checked~.player #test {
    transform: translateY(-80px);
}


@media only screen and (min-width: 768px) {
    .card {
        width: 100px;
        height: 100px;
    }
    .cards {
        height: 100px;
        max-width: 650px;
    
        img {
            width: 100px;
        }
    }
    
.player {
    background-color: #fff;
    min-width: 320px;
    padding: 15px 10px;
    border-radius: 40px;

    .upper-part {
        height: 47px;
        .play-icon {
            img {
                height: 30px;
                object-fit: cover;
            }
        }
    }

    .song-info .title {
        font-size: 15px;
        line-height: 24px;
    }

    .subtitle {
        font-size: 14px;
        line-height: 17px;
    }

    .time {
        font-size: 18px;
    }
}
}