#change-username-page {
    background: white;
    width: 100%;
    height: 100vh;
    padding: 5vw;
    display: flex;
    flex-direction: column;
    align-items: center;
    color: #404040
}

.change-username-page-container {
    width: 40%;
    max-width: 350px;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.change-username-header{
    width: 100%;
    margin: 10px 0px;
    text-align: left;
    font-size: 13px;
}

#change-username-form {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    label {
        width: 100%;
        margin: 10px 0px;
        text-align: left;
        font-size: 12px;
        color: #5f5f5f;
    }
    
    input {
        width: 100%;
        border-radius: 10px;
        padding: 15px 10px;
        font-size: 15px;
        border: 1px solid #c8c8c8;
    }
}

.change-username-username-availability-status{
    width: 100%;
    font-size: 12px;
    text-align: center;
}


.change-username-submit-button {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    margin: 10px 0px;
    padding: 10px;
    background: #3e4684;
    color: hsl(0 0 100);
    border: none;
    border-radius: 30px;
}

.change-username-submit-button-enabled {
    background-color: #3e4684;
    cursor: pointer;
}

.change-username-submit-button-disabled {
    background-color: #ccc;
    color: #888;
    cursor: not-allowed;
}


@media (max-width: 600px),
(max-height: 600px) {

    #change-username-page {
        height: auto;
    }

    .change-username-page-container {
        width: 100%;
    }

}