.animated-toggle input[type="checkbox"] {
  display: none;
  /* Hide the default checkbox */
}

.animated-toggle .toggle {
  position: relative;
  display: inline-block;
  width: 100%;
  height: 100%;
  cursor: pointer;
}

.animated-toggle .toggle:before {
  content: "";
  position: relative;
  display: block;
  top: 5px;
  left: 0px;
  width: 100%;
  height: 50%;
  background: #9a9999;
  border-radius: 50px;
  transition: background 0.2s ease;
}

.animated-toggle .toggle span {
  position: absolute;
  top: 0;
  left: 0;
  width: 50%;
  height: 100%;
  display: block;
  background: white;
  border-radius: 50%;
  box-shadow: 0 3px 8px rgba(154, 153, 153, 0.5);
  transition: all 0.2s ease;
}

.animated-toggle #cbx:checked+.toggle:before {
  background: #947ada;
}

.animated-toggle #cbx:checked+.toggle span {
  transform: translateX(100%);
  background: #4f2edc;
  box-shadow: 0 3px 8px rgba(79, 46, 220, 0.2);
}

.animated-toggle #cbx:checked+.toggle span:before {
  transform: scale(1);
  opacity: 0;
  transition: all 0.4s ease;
}

.animated-toggle input[type="checkbox"],
.animated-toggle label {
  pointer-events: none;
  /* Disable pointer events on both input and label */
}