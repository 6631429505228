.welcome-page {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: auto;
    width: 100%;
    padding: 10px;
    display: grid;
    color: #5f5f5f;
    background: white;
}

.welcome-page-platform-name-animation-title {
    grid-column: 1 / 3;
    grid-row: 1;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: baseline;

    .title {
        text-align: center;
        font-size: medium;
        color: rgb(36, 36, 36);
    }

    .animation-text {
        margin: 0px 5px;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: baseline;
    }
}

.welcome-page-logo-div {
    grid-column: 1 / 3;
    grid-row: 2;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;

    .welcome-page-logo {
        height: 20vh;
    }
}

.welcomepage-short-description-div {
    grid-column: 1 / 3;
    grid-row: 3;
    width: 100%;
    padding: 5px 0px;

    p {
        font-size: 14px;
        text-align: center;
    }
}

.welcome-page-grid-sub-heading {
    display: inline-block;
    width: 100%;
    margin: 20px 0px;
    padding: 5px;
    border-radius: 30px;
    font-weight: 700;
    text-align: center;
    color: rgb(41, 41, 41);
}

.welcome-page-navigation-buttons button,
.welcome-page-auth-buttons button {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    background: #b9d0e7;
    color: white;
    font-weight: bold;
    padding: 10px;
    width: 100%;
    max-width: 300px;
    text-align: left;
    margin: 10px 0px;
    border: none;
    border-radius: 20px;
    box-shadow: 0px 0px 5px rgb(154, 154, 154);

    img {
        height: 25px;
        margin: 0px 10px;
    }

    .welcome-page-button-right-arrow {
        height: 20px;
        margin-right: 10px;
        margin-left: auto;
    }
}

.welcome-page-navigation-buttons {
    width: 100%;
    grid-column: 1/3;
    grid-row: 4;
    padding: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.welcome-page-auth-buttons {
    grid-column: 1/3;
    grid-row: 4;
    padding: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    .welcome-page-register-button {
        background: #030a15;
    }
}

.welcome-page-platform-carousel-div {
    grid-column: 1;
    grid-row: 5;
    margin: 10px 0px;
    border-radius: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;

    .welcome-page-platform-carousel {
        height: fit-content;
        display: flex;
        flex-direction: column;
        justify-content: center;
        max-width: 700px;
    }
}

.welcome-page-user-guide-div {
    grid-column: 2;
    grid-row: 5;
    width: 100%;
    margin: 10px 0px;
    padding: 0px 10px;
    border-radius: 20px;

    .welcome-page-user-guide {
        display: flex;
        flex-direction: column;
        justify-content: center;
    }

    .welcome-page-user-guide-trynow-div {
        height: 100px;
        width: 100%;
        border-radius: 15px;
    }
}

.welcomepage-description-div {
    grid-column: 1/3;
    grid-row: 6;
    width: 100%;
    padding: 20px;

    p {
        font-size: 10px;
        text-align: center;
    }
}

.user-guide-timeline-div {
    width: 100%;
    height: fit-content;
    max-width: 400px;
    margin-bottom: 10vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
}



@media only screen and (max-width: 768px) {

    .welcome-page-platform-name-animation-title {
        flex-direction: column;

        .title {
            width: 100%;
        }
    }

    .animation-text {
        width: 100%;
    }

    .welcome-page-platform-carousel-div {
        border: 1px solid rgb(198, 198, 198);
    }

    .welcome-page-user-guide-div {
        border: 1px solid rgb(198, 198, 198);
    }

    .welcome-page {
        grid-template-columns: 1fr;
        gap: 20px;
    }

    .welcome-page-user-guide-div,
    .welcome-page-platform-carousel-div,
    .welcome-page-navigation-buttons,
    .welcome-page-auth-buttons,
    .welcomepage-short-description-div,
    .welcome-page-logo-div,
    .welcome-page-platform-name-animation-title,
    .welcomepage-description-div {
        grid-column: span 1;
        grid-row: auto;
    }
}