/* Background blur effect */
.confirmation-popup-blur-background {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background: rgba(61, 61, 61, 0.4);
    backdrop-filter: blur(1px);
    z-index: 130;
    pointer-events: none;
  }
  
  /* Overlay to cover the entire background content */
  .confirmation-popup-overlay {
    position: fixed;
    top: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background: rgba(0, 0, 0, 0);
    z-index: 131;
  }
  
  .confirmation-popup-box {
    position: fixed;
    height: 35%;
    padding: 15px 30px;
    background-color: rgb(255, 255, 255);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border-radius: 15px;
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.2);
    z-index: 132;
    overflow-wrap: anywhere;
  }
  
  .confirmation-popup-message {
    height: 70%;
    display: flex;
    justify-content: center;
    text-align: center;
    align-items: center;
    font-size: 20px;
    font-weight: bold;
    color: #494949;
  }

  .confirmation-popup-buttons-div{
    height: 30%;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .confirmation-popup-okay-button {
    width: 100%;
    border: none;
    background: rgb(63, 65, 91);
    color: white;
    border-radius: 5px;
    padding: 5px;
    margin: 5px;
  }
  .confirmation-popup-okay-button:hover {
    background-color: #0e1634;
    color: white;
  }

  .confirmation-popup-close-button {
    border: none;
    background: none;
    color: rgb(77, 77, 77);
    border-radius: 5px;
    padding: 5px 20px;
  }
  .confirmation-popup-close-button:hover {
    background-color: #0e1634;
    color: white;
  }
  
  
  @media (max-width: 600px), (max-height: 600px) {
  
    .confirmation-popup-box {
      width: 80%;
    }
  
    .confirmation-popup-message {
      font-size: calc(0.6em + 2vw);
    }
  
    .confirmation-popup-close-button-div {
      /* margin: 0px 10px; */
      button {
        padding: 4px 15px;
      }
    }
  
  }