@import url('https://fonts.googleapis.com/css2?family=Fira+Sans&family=Plus+Jakarta+Sans:wght@300;400;500;700&display=swap');


/* Scrollbar styling */
::-webkit-scrollbar {
  width: 10px;
}

::-webkit-scrollbar-track {
  background: none;
}

::-webkit-scrollbar-thumb {
  background-color: #888;
  border-radius: 10px;
  border: none;
}

::-webkit-scrollbar-thumb:hover {
  background: #555;
}

/* Scrollbar styling */



body {
  font-family: "Plus Jakarta Sans", "Fira Sans", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, sans-serif;
}

h1, h2, h3, p {
  font-family: "Plus Jakarta Sans", "Fira Sans", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, sans-serif;
}

body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

* {
  transition: all 0.3s ease;
}

a:hover, button:hover {
  transform: translateY(-2px);
}

/* Style for the '?' circle */
.tooltip-container {
  position: relative;
  display: inline-block;
  cursor: pointer;
}

.tooltip-circle {
  background-color: gray;
  color: white;
  margin: 0px 5px;
  border-radius: 50%;
  width: 20px;
  height: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 12px;
  font-weight: bold;
  text-align: center;
  line-height: 1;
}

/* Hidden tooltip box */
.tooltip-text {
  visibility: hidden;
  background-color: black;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 10px;
  position: absolute;
  bottom: 150%; /* Adjust as per the element's placement */
  left: 50%;
  transform: translateX(-50%);
  z-index: 1;
  max-width: 300px; /* Maximum width for PC */
  width: 60vw; /* Width for mobile devices */
  overflow: hidden; /* Hide overflow text */
  white-space: normal; /* Allow text to wrap */
  text-overflow: ellipsis; /* Add ellipsis for overflowed text */
  opacity: 0;
  transition: opacity 0.3s ease-in-out;
}


/* Tooltip arrow */
.tooltip-text::after {
  content: '';
  position: absolute;
  top: 100%;
  left: 50%;
  transform: translateX(-50%);
  border-width: 5px;
  border-style: solid;
  border-color: black transparent transparent transparent;
}

/* Show the tooltip on hover or focus */
.tooltip-container:hover .tooltip-text,
.tooltip-container:focus .tooltip-text {
  visibility: visible;
  opacity: 1;
}

@media only screen and (max-width: 768px) {
  ::-webkit-scrollbar {
    width: 5px;
  }

  .tooltip-circle {
    width: 15px;
    height: 15px;
  }
  .tooltip-text {
    width: 60vw; /* Remove the mobile width on larger screens */
    max-width: 300px; /* Maintain maximum width */
}
}
