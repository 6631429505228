.add-product-page {
  /* background: hsl(209.4deg 100% 98.11%); */
  background: white;
  width: 100%;
  height: 100vh;
  min-width: 200px;
  min-height: 300px;
}

.add-product-page-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 20px;
  color: #333;
}

#add-product-header-div {
  color: #a9a8a8;
  margin: 5vh 0vh;
  text-align: center;
}

.add-product-form-container {
  padding: 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.add-product-form {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  box-shadow: 0px 0px 2px rgb(76, 76, 76);
  padding: 50px 70px;
}

.add-product-form-error-text {
  text-align: left;
  font-weight: 300;
  color: red;
}

.add-product-form a {
  color: rgb(32 104 139);
  text-decoration: underline;
  font-family: cursive;
}

.add-product-form a:hover {
  color: rgb(8, 30, 41);
  text-decoration: none;
}

.add-product-label-name{
  width: 100%;
  text-align: left;
  font-weight: bold;
  color: #545454;
  display: flex;
  flex-direction: row;
  align-items: center;
  .add-product-question-mark{
    background-color: gray;
    border-radius: 100%;
  }
}

.add-product-label {
  width: 100%;
  margin-bottom: 20px;
  text-align: left;
  font-weight: bold;
  color: #545454;

  button {
    border: none;
    background: none;
    margin: 5px 15px;
    padding: 10px;
    cursor: pointer;
    border-bottom: 1px solid rgb(171, 171, 171);
    border-radius: 10px;
  }

  button.selected {
    border: 1px solid #000000;
    /* Highlight border color */
    border-radius: 10px;
    /* Optional rounded corners */
  }

  button img {
    display: block;
  }

}

.add-product-input {
  width: 100%;
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-sizing: border-box;
}

.add-product-add-button-div {
  width: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  font-size: 15px;

  button {
    width: 100%;
    max-width: 300px;
    background: #0e1634;
    border: none;
    color: white;
    border-radius: 20px;
    padding: 10px 30px;
    margin: 20px 0px;
  }

  button:hover {
    background-color: #1a295e;
  }
}

.add-product-side-bar-main-div {
  width: 20vw;
  height: 100vh;
  padding: 20px;
  word-wrap: break-word;
  background-color: rgb(75, 149, 153);
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.2);
}

.add-product-sidebar-inner-div {
  margin: 0px;
}

@media only screen and (max-width: 768px) {
  .add-product-page {
    padding: 20px 10px;
  }

  .add-product-page-container {
    padding: 0px;
  }

  #add-product-header-div {
    margin: 3vh;
  }

  .add-product-form {
    padding: 0px 30px;
    margin: 5px;
    box-shadow: none;
    font-size: calc(0.4em + 1.5vw);
  }
}