.footer{
  background: #030a15;
  color: white;
  height: 10vh;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  margin-bottom: 0px;
  margin-top: auto;
}

.footer-about-developer-button{
  font-size: 12px;
  margin: 0px 10px;
}

.footer-icon-div{
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: baseline;

  img{
    height: 30px;
    margin: 10px;
  }
}



@media only screen and (max-width: 768px) {
  .footer-icon-div{
    img{
      height: 25px;
      margin: 7px;
    }
  }
}